<template>
  <div class="label-group-container">
    <el-scrollbar style="height:100%">
      <el-card v-if="show_new" class="box-card">
        <el-select v-model="gradeId" placeholder="请选择年级" size="mini">
          <el-option
            v-for="item in gradeIdOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select v-model="subjectId" placeholder="请选择学科" size="mini" style="margin-left:15px">
          <el-option
            v-for="item in subjectIdOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button
          type="primary"
          size="mini"
          style="margin-bottom:20px;margin-left:20px"
          @click="search"
        >搜索</el-button>
        <el-button type="primary" size="mini" @click="replacement">重置</el-button>
        <el-button type="primary" @click="new_label" class="new_label" size="mini">新建标签</el-button>
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="gradeName" label="年级"></el-table-column>
          <el-table-column prop="subjectName" label="学科"></el-table-column>
          <el-table-column prop="name" label="模板名称"></el-table-column>
          <el-table-column prop="creatorTime" label="创建时间"></el-table-column>
          <el-table-column width="160" label="操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                class="el-icon-edit"
                @click="operation('edit',scope.row)"
                size="small"
              ></el-button>
              <el-button
                type="danger"
                class="el-icon-delete"
                @click="operation('delete',scope.row)"
                size="small"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="handleCurrentChange"
          :page-size="page.pageSize"
          :current-page.sync="page.pageNum"
          layout="total, prev, pager, next"
          :total="page.total"
          background
        ></el-pagination>
      </el-card>
      <el-card class="box-card" v-if="!show_new">
        <el-divider>{{title_msg}}</el-divider>
        <el-form :model="result" :rules="rules_f" ref="result" class="result_f_class">
          <el-form-item label="年级" prop="gradeId">
            <el-select v-model="result.gradeId" placeholder="请选择年级" size="mini">
              <el-option
                v-for="item in gradeIdOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学科" prop="subjectId">
            <el-select v-model="result.subjectId" placeholder="请选择学科" size="mini">
              <el-option
                v-for="item in subjectIdOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="模板名称："
            style="vertical-align: middle"
            prop="name"
            class="rules_f_class"
          >
            <el-input
              style="width: 250px; margin-right: 20px"
              v-model="result.name"
              type="text"
              placeholder="（必填20字以内）请输入模板名称"
              size="mini"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
        <label-group
          :arr="result.label_more"
          @change-final-label="changeFinalLabel"
          :new_validate="new_validate"
          @verification_father="verificationFather"
        ></label-group>
        <div class="btn-group">
          <el-button @click="cancelHandleSave">取消</el-button>
          <el-button type="primary" @click="handleSave('result')">保存</el-button>
        </div>
      </el-card>
    </el-scrollbar>
  </div>
</template>



<script>
import LabelGroup from "./LabelGroup.vue";
import LabelItem from "../../components/LabelItem";
import { templateAdd, templateList, templateRemove, templateDetail, templateUpdate } from '../../api/api'
import moment from 'moment'
export default {
  name: "",
  props: {},
  data () {
    return {
      page: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      gradeId: '',
      subjectId: '',
      title_msg: '',
      title_num: null,
      gradeList: [],
      tableData: [],
      subjectList: [],
      gradeIdOptions: this.parameter.grade(),
      subjectIdOptions: this.parameter.subject(),
      result: {
        gradeId: null,
        gradeName: null,
        id: null,
        label_more: [
          {
            // 一级
            child_label: [],
            code: "",
            extendContent: [],
            id: null,
            labelType: 0,
            leafNodeFlag: false,
            lengthLimit: null,
            name: "",
            numberLowerLimit: null,
            numberSupperLimit: null,
            remark: "",
            status: 0,
          },
        ],
        name: "",
        remark: "人教版",
        subjectId: null,
        subjectName: null,
      },
      rules_f: {
        name: [
          { required: true, message: '请输入模板名称', trigger: 'blur' },
        ],
        gradeId: [
          { required: true, message: '请选择年级', trigger: 'change' }
        ],
        subjectId: [
          { required: true, message: '请选择学科', trigger: 'change' }
        ],
      },
      deleteModal: false,
      curDeleteIdx: null,
      /*  */
      // 自定义标签名称
      labels: {
        matchType: null,
        matchTypes: [
          { id: "all", label: "All" },
          { id: "any", label: "Any" },
        ],
        addRule: "添加所属标签 Add Rule",
        removeRule: "&times;",
        addGroup: "添加所属子维度 add addGroup",
        removeGroup: "&times;",
        textInputPlaceholder01: "请输入标签名称",
        textInputPlaceholder02: "请输入文本内容",
        textInputPlaceholder03: "请输入单选选项，以空格分隔",
        textInputPlaceholder04: "请输入多选选项，以空格分割",
      },
      matchType: "",
      // 标签类型
      // 0无类型 1数值 2单选 3多选 4字符串
      rules: [
        {
          id: "numeric",
          type: "numeric",
          label: "数值标签",
          labelType: 1,
        },
        {
          type: "radio",
          id: "radio",
          label: "单选标签",
          labelType: 2,
          choices: [
            { label: "Standard", value: "standard" },
            { label: "Premium", value: "premium" },
          ],
        },
        {
          type: "checkbox",
          id: "checkbox",
          label: "多选标签",
          labelType: 3,
          choices: [
            { label: "Small", value: "small" },
            { label: "Medium", value: "medium" },
            { label: "Large", value: "large" },
          ],
        },
        {
          id: "text",
          type: "text",
          label: "文本标签",
          labelType: 4,
        },
      ],
      label_permit: true,
      output: {},
      labelData: {},
      show_new: true,
      new_validate: [],
      verificationFather_value: null,
      sortData: [],
      sortData_s: [],
      sortData_t: [],
      sortLabel: [],
      sortLabel_t: [],
      current: true,
      joint_data: {
        name: '',
        tag: [],
        children: []
      },
      echo_arr: [],
    };
  },
  components: {
    LabelGroup,
    LabelItem,
    // VueQueryBuilder,
  },
  watch: {},
  computed: {},
  methods: {
    // 搜索
    search () {
      this.page.pageNum = 1
      this.showTemplateList()
    },
    // 重置
    replacement () {
      this.gradeId = ''
      this.subjectId = ''
      this.showTemplateList()
    },
    // 换页
    handleCurrentChange (val) {
      this.page.pageNum = val
      this.showTemplateList()
    },
    // 操作
    operation (type, val) {
      this.echo_arr = []
      if (type == 'edit') {
        this.title_msg = '编辑标签模板'
        this.title_num = 1
        templateDetail({ 'id': val.id }).then(res => {
          if (res.code == 0) {
            this.result.gradeId = res.data.gradeId
            this.result.subjectId = res.data.subjectId
            this.result.name = res.data.name
            this.result.id = res.data.id
            // this.echo_data(this.cs_obj.label, this.echo_arr)
            this.echo_data(res.data.label, this.echo_arr)
            setTimeout(() => {
              this.result.label_more = this.echo_arr
            }, 100);
            this.show_new = false
          } else {
            this.$Message.error(res.message)
          }
        })
      } else {
        this.$confirm('此操作将永久删除该模板, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          templateRemove([val.id]).then(res => {
            if (res.code == 0) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.showTemplateList()
            } else {
              this.$message.error(res.message)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },
    // 标签模板列表
    showTemplateList () {
      let params = {
        pageSize: this.page.pageSize,
        pageNum: this.page.pageNum,
        gradeId: this.gradeId, //班级ID
        subjectId: this.subjectId
      }
      templateList(params).then(res => {
        if (res.data) {
          res.data.list.map(item => {
            item.creatorTime = moment(item.creatorTime).format('YYYY-MM-DD HH:mm:ss')
            this.subjectIdOptions.map(itm => {
              if (itm.value == item.subjectId) {
                item.subjectName = itm.label
              }
            })
            this.gradeIdOptions.map(itm => {
              if (itm.value == item.gradeId) {
                item.gradeName = itm.label
              }
            })
          })
          this.tableData = res.data.list
          this.page.total = res.data.total
        }
      })
    },
    verificationFather (val) {
      this.verificationFather_value = val
    },
    addTopLable () {
      this.labels.matchType = this.matchType;
    },
    // 打开新建标签界面
    new_label () {
      this.title_msg = '新建标签模板'
      this.title_num = 0
      this.show_new = false
    },
    getId (arr, tar) {
      return arr.filter((item) => item.value == tar);
    },
    async getSubjectChoiceLabel () {
      let res = await getSubjectChoiceLabel();
      if (res.data.success) {
        this.subjectList = res.data.data;
      } else {
        this.$Message.error(res.data.message);
      }
    },
    deleteFieldStarted (i) {
      this.curDeleteIdx = i;
      this.deleteModal = true;
    },
    // 确定新建标签
    handleSave (formName) {
      this.joint_data = {
        name: '',
        tag: [],
        children: [],
        switch_value: 2
      }
      // 每一次点击创建都置为true
      this.label_permit = true
      this.$refs[formName].validate((valid) => {
        this.processing_data(this.result.label_more, this.joint_data)
        if (valid && this.label_permit && this.joint_data.children.length > 0) {
          if (this.title_num == 1) {
            this.labelData.id = this.result.id
          }
          this.labelData.name = this.result.name
          this.labelData.gradeId = this.result.gradeId
          this.labelData.subjectId = this.result.subjectId
          this.labelData.label = this.joint_data.children
          if (this.title_num == 0) {
            templateAdd(this.labelData).then(res => {
              if (res.code == 0) {
                this.$message({
                  message: '新建成功！',
                  type: 'success'
                });
                this.showTemplateList()
                this.show_new = true
              } else {
                this.$message.error(res.message);
              }
            })
            this.cancelHandleSave()
          } else if (this.title_num == 1) {
            templateUpdate(this.labelData).then(res => {
              if (res.code == 0) {
                this.$message({
                  message: '编辑成功！',
                  type: 'success'
                });
                this.showTemplateList()
                this.show_new = true
              } else {
                this.$message.error(res.message);
              }
            })
            this.cancelHandleSave()
          }
        } else {
          this.$message({
            message: '标签模板信息不完整！',
            type: 'warning'
          });
          return false;
        }
      });
    },
    // 新建标签处理数据
    processing_data (val, arr) {
      val.map((first_template, first_index) => {
        let obj = {
          name: '',
          tag: [],
          children: [],
          switchValue: 2,//1 必填  2 非必填
        }
        obj.name = first_template.name
        obj.switchValue = first_template.switch_value ? 1 : 2
        // name校验
        if (first_template.name == '') {
          this.label_permit = false
        }
        if (first_template.labelType == 0) {
          first_template.child_label.map((label_template, label_index) => {
            // name校验
            if (label_template.name == '') {
              this.label_permit = false
            }
            let obj_template = {
              name: '',
              tag: [],
              children: [],
              switchValue: 2
            }
            if (label_template.labelType != 0) {
              let obj_label = {
                name: null, //标签名字
                type: null,//类型
                content: null, //选项
                numberLowerLimit: null,//最小值
                numberSupperLimit: null,//最大值
              }
              obj_label.name = label_template.name
              obj_label.type = label_template.labelType
              if (label_template.labelType == 1) {
                obj_label.content = label_template.numberLowerLimit + '~' + label_template.numberSupperLimit
              } else if (label_template.labelType == 2 || label_template.labelType == 3) {
                // 单选多选校验
                if (label_template.strExtendContent == '') {
                  this.label_permit = false
                }
                obj_label.content = label_template.strExtendContent
              }
              obj.tag.push(obj_label)
            } else if (label_template.labelType == 0) {
              obj_template.name = label_template.name
              obj_template.switchValue = label_template.switch_value ? 1 : 2
              obj.children.push(obj_template)
              // 判断维度下没有标签或者维度并且label_permit是true时置换false
              if (label_template.child_label.length == 0) {
                this.label_permit = false
              }
              this.processing_data(label_template.child_label, obj_template)
            }
          })
          // 判断维度下没有标签或者维度并且label_permit是true时置换false
          if (first_template.child_label.length == 0) {
            this.label_permit = false
          }
        } else if (first_template.labelType != 0) {
          let obj_label = {
            name: null, //标签名字
            type: null,//类型
            content: null, //选项
            numberLowerLimit: null,//最小值
            numberSupperLimit: null,//最大值
          }
          obj_label.name = first_template.name
          obj_label.type = first_template.labelType
          if (first_template.labelType == 1) {
            obj_label.content = first_template.numberLowerLimit + '~' + first_template.numberSupperLimit
          } else if (first_template.labelType == 2 || first_template.labelType == 3) {
            // 单选多选校验
            if (first_template.strExtendContent == '') {
              this.label_permit = false
            }
            obj_label.content = first_template.strExtendContent
          }
          arr.tag.push(obj_label)
        }
        if (first_template.labelType == 0) {
          arr.children.push(obj)
        }
      })
      return arr
    },
    // 回显数据处理
    echo_data (val, arr) {
      val.map((first_template, first_index) => {
        let first_template_obj = {
          // 维度
          child_label: [],
          code: "",
          extendContent: [],
          id: null,
          labelType: 0,
          leafNodeFlag: false,
          lengthLimit: null,
          name: "",
          numberLowerLimit: null,
          numberSupperLimit: null,
          remark: "",
          status: 0,
          switch_value: false
        }
        first_template_obj.name = first_template.name
        first_template_obj.switch_value = first_template.switchValue == 1 ? true : false
        if (first_template.tag.length > 0) {
          first_template.tag.map((first_tag, tag_index) => {
            let first_tag_obj = {
              // 标签
              child_label: [],
              code: "",
              extendContent: [],
              id: null,
              labelType: 0,
              leafNodeFlag: false,
              lengthLimit: null,
              name: "",
              numberLowerLimit: null,
              numberSupperLimit: null,
              remark: "",
              status: 0,
              switch_value: false
            }
            first_tag_obj.labelType = first_tag.type
            first_tag_obj.name = first_tag.name
            first_tag_obj.switch_value = first_tag.switchValue == 1 ? true : false
            if (first_tag.type == 1) {
              let numeric_arr = first_tag.content.split('~')
              first_tag_obj.numberLowerLimit = numeric_arr[0]
              first_tag_obj.numberSupperLimit = numeric_arr[1]
            } else if (first_tag.type == 2 || first_tag.type == 3) {
              first_tag_obj.strExtendContent = first_tag.content
              let content_arr = first_tag.content.split(',')
              content_arr.map((content, content_index) => {
                first_tag_obj.extendContent.push({ id: content_index + 1, name: content })
              })
            }
            switch (first_tag.type) {
              case 1:
                first_tag_obj.labelTypeText = "数值标签：";
                break;
              case 2:
                first_tag_obj.labelTypeText = "单选标签：";
                break;
              case 3:
                first_tag_obj.labelTypeText = "多选标签：";
                break;
              case 4:
                first_tag_obj.labelTypeText = "文本标签：";
                break;
            }
            first_template_obj.child_label.push(first_tag_obj)
          })
        }
        if (first_template.children.length > 0) {
          this.echo_data(first_template.children, first_template_obj.child_label)
        }
        arr.push(first_template_obj)
      })
      return arr
    },
    // 取消创建
    cancelHandleSave () {
      this.result = {
        gradeId: null,
        gradeName: null,
        id: null,
        label_more: [
          {
            // 一级
            child_label: [],
            code: "",
            extendContent: [],
            id: null,
            labelType: 0,
            leafNodeFlag: false,
            lengthLimit: null,
            name: "",
            numberLowerLimit: null,
            numberSupperLimit: null,
            remark: "",
            status: 0,
          },
        ],
        name: "",
        remark: "人教版",
        subjectId: null,
        subjectName: null,
      }
      this.sortData = []
      this.sortLabel = []
      this.sortLabel_t = []
      this.show_new = true
    },
    changeFinalLabel (payload) {
      this.result.label_more = payload;
    }
  },
  created () { },
  mounted () {
    this.showTemplateList()
  },
};
</script>

<style lang='less' scoped>
@import './style/index.less';
@import '../../style/mouse.less';
</style>